





























































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import PageTitle from '@/components/specific/PageTitle.vue';
import { MESSAGE_UPDATE_DONE, MESSAGE_GENERATE_API_KEY_DONE } from '@/resources/defines';
import getUserAttributesValue from '@/resources/functions/getUserAttributesValue';
import isEmptyOrOnlySpace from '@/resources/functions/isEmptyOrOnlySpace';
import spritAndTrim from '@/resources/functions/spritAndTrim';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import store from '@/store';
import { DomainAuth, DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommon, UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');
const PartnerService = ServiceFactory.get('partner');

export default Vue.extend({
  name: 'ProfilePage',

  components: {
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    callerPhone: string;
    allowIPAddresses: string[];
    loading: {
      account: boolean;
      ipAddresses: boolean;
      login: boolean;
      subMail: boolean;
      apiKey: boolean;
    };
    showedDialog: {
      [key: string]: boolean;
    };
    subMail: {
      billing_mails: string[];
      contact_mails: string[];
      failure_mails: string[];
    };
    subscriptionName: string;
    updateParams: {
      account: {
        company_name: string;
        family_name: string;
        family_name_kana: string;
        given_name: string;
        given_name_kana: string;
      };
      allowIPAddresses: {
        ipaddrs: string;
      };
      login: {
        email: string;
      };
      subMail: {
        billing_mails: string;
        contact_mails: string;
        failure_mails: string;
      };
    };
  } {
    return {
      allowIPAddresses: [],
      callerPhone: '',
      loading: {
        account: false,
        apiKey: false,
        ipAddresses: false,
        login: false,
        subMail: false,
      },
      showedDialog: {
        account: false,
        apiKey: false,
        ipAddresses: false,
        loginMailAddress: false,
        otherMailAddress: false,
      },
      subMail: {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        billing_mails: [],
        contact_mails: [],
        failure_mails: [],
        /* eslint-enable @typescript-eslint/camelcase */
      },
      subscriptionName: '',
      updateParams: {
        account: {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          company_name: '',
          family_name: '',
          family_name_kana: '',
          given_name: '',
          given_name_kana: '',
          /* eslint-enable @typescript-eslint/camelcase */
        },
        allowIPAddresses: {
          ipaddrs: '',
        },
        login: {
          email: '',
        },
        subMail: {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          billing_mails: '',
          contact_mails: '',
          failure_mails: '',
          /* eslint-enable @typescript-eslint/camelcase */
        },
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const procedures = [
        AuthService.getUserAttributes(),
        PartnerService.getPartner({
          fields: [
            'PackageVars.BillingNotifyMails',
            'PackageVars.ContactMails',
            'PackageVars.FailureNotifyMails',
            'PackageVars.Stripe.Subscriptions',
            'inhSvcConf.AllowIpAddr.Customers',
          ],
        }),
        PartnerService.getCallerId(),
      ];

      const [responseUserAttributes, responsePartner, responseCallerId] = await Promise.all(
        procedures
      );

      const domainAuthContext = DomainAuth.context(store);
      const { setUserAttributes } = domainAuthContext.actions;

      setUserAttributes({
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        company_name: getUserAttributesValue(responseUserAttributes, 'custom:company_name'),
        email: getUserAttributesValue(responseUserAttributes, 'email'),
        family_name: getUserAttributesValue(responseUserAttributes, 'family_name'),
        family_name_kana: getUserAttributesValue(responseUserAttributes, 'custom:family_name_kana'),
        given_name: getUserAttributesValue(responseUserAttributes, 'given_name'),
        given_name_kana: getUserAttributesValue(responseUserAttributes, 'custom:given_name_kana'),
        partner_id: getUserAttributesValue(responseUserAttributes, 'custom:partner_id'),
        /* eslint-enable @typescript-eslint/camelcase */
      });

      // コンポーネント内のすべてが対象なのでanyを許容する
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next((vm: any) => {
        // コンポーネントにデータを渡すために代入が必要
        /* eslint-disable-next-line no-param-reassign */
        vm.subMail = {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          billing_mails: _.get(responsePartner, 'PackageVars.BillingNotifyMails', []),
          contact_mails: _.get(responsePartner, 'PackageVars.ContactMails', []),
          failure_mails: _.get(responsePartner, 'PackageVars.FailureNotifyMails', []),
          /* eslint-enable @typescript-eslint/camelcase */
        };

        // コンポーネントにデータを渡すために代入が必要
        /* eslint-disable-next-line no-param-reassign */
        vm.callerPhone = _.get(responseCallerId, 'forward_extension[0].Phone', '');

        // コンポーネントにデータを渡すために代入が必要
        /* eslint-disable-next-line no-param-reassign */
        vm.subscriptionName = _.get(
          responsePartner,
          'PackageVars.Stripe.Subscriptions[0].name',
          ''
        );

        // コンポーネントにデータを渡すために代入が必要
        /* eslint-disable-next-line no-param-reassign */
        vm.allowIPAddresses = _.get(responsePartner, 'inhSvcConf.AllowIpAddr.Customers', []);
      });
    } catch (error) {
      Log.error(error);

      const uiCommonContext = UICommon.context(store);
      const { setErrorMessage, setNavigating } = uiCommonContext.actions;
      setErrorMessage({ text: error.message });
      setNavigating({ navigating: false });

      next(false);
    }
  },

  mounted() {
    const appMain = document.getElementById('app-main');

    if (appMain !== null) {
      appMain.scrollIntoView();
    }
  },

  methods: {
    ...DomainAuthMapper.mapActions(['setUserAttributesSpecified']),
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),

    async regenerateAPIKey() {
      const self = this;

      self.loading.apiKey = true;

      try {
        await PartnerService.regenerateAPIKey();

        self.setMessage({
          color: 'success',
          text: MESSAGE_GENERATE_API_KEY_DONE,
        });

        self.loading.apiKey = false;
        self.showedDialog.apiKey = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading.apiKey = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    showAccountDialog() {
      const self = this;

      // APIによりパラメータ名が定義されているため除外
      /* eslint-disable @typescript-eslint/camelcase */
      self.updateParams.account.family_name = self.userAttributes.family_name;
      self.updateParams.account.given_name = self.userAttributes.given_name;
      self.updateParams.account.family_name_kana = self.userAttributes.family_name_kana;
      self.updateParams.account.given_name_kana = self.userAttributes.given_name_kana;
      self.updateParams.account.company_name = self.userAttributes.company_name;
      /* eslint-enable @typescript-eslint/camelcase */

      self.showedDialog.account = true;
    },

    showIPAddressDialog() {
      const self = this;

      self.updateParams.allowIPAddresses.ipaddrs = _.join(self.allowIPAddresses, '\n');

      self.showedDialog.ipAddresses = true;
    },

    showLoginEmailDialog() {
      const self = this;

      self.updateParams.login.email = self.userAttributes.email;

      self.showedDialog.loginMailAddress = true;
    },

    showSubMailDialog() {
      const self = this;

      // APIによりパラメータ名が定義されているため除外
      /* eslint-disable @typescript-eslint/camelcase */
      self.updateParams.subMail.billing_mails = _.join(self.subMail.billing_mails, ', ');
      self.updateParams.subMail.contact_mails = _.join(self.subMail.contact_mails, ', ');
      self.updateParams.subMail.failure_mails = _.join(self.subMail.failure_mails, ', ');
      /* eslint-enable @typescript-eslint/camelcase */

      self.showedDialog.otherMailAddress = true;
    },

    async updateAccount() {
      const self = this;

      self.$v.updateParams.account.$touch();

      // バリデーション
      if (self.$v.updateParams.account.$invalid) {
        self.$$log.debug(self.$v.updateParams.account);
        return;
      }

      // 任意項目に空白文字のみ入力されたときは空文字に変換
      const updateParams = {
        // APIによりパラメータ名が定義されているため除外
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_attributes: _.assign({}, self.updateParams.account, {
          // APIによりパラメータ名が定義されているため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          company_name: isEmptyOrOnlySpace(self.updateParams.account.company_name)
            ? ''
            : self.updateParams.account.company_name,
        }),
      };

      self.loading.account = true;

      try {
        await AuthService.updateUserAttributes(updateParams);

        self.setMessage({
          color: 'success',
          text: MESSAGE_UPDATE_DONE,
        });

        self.loading.account = false;

        self.setUserAttributesSpecified(updateParams.user_attributes);

        self.showedDialog.account = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading.account = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    async updateAllowIPAddresses() {
      const self = this;

      self.$v.updateParams.allowIPAddresses.ipaddrs.$touch();

      // バリデーション
      if (self.$v.updateParams.allowIPAddresses.ipaddrs.$invalid) {
        self.$$log.debug(self.$v.updateParams.allowIPAddresses.ipaddrs);
        return;
      }

      self.loading.ipAddresses = true;

      try {
        const updateParams = {
          // _.compact()は空値以外も除去するが、バリデーション通過後なので問題ない
          ipaddrs: _.compact(spritAndTrim(self.updateParams.allowIPAddresses.ipaddrs, '\n')),
        };

        const result = await PartnerService.updateAllowIPAddresses(updateParams);

        self.allowIPAddresses = _.get(result, 'ipaddrs', []);

        self.setMessage({
          color: 'success',
          text: MESSAGE_UPDATE_DONE,
        });

        self.loading.ipAddresses = false;
        self.showedDialog.ipAddresses = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading.ipAddresses = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    async updateLoginEmail() {
      const self = this;

      self.$v.updateParams.login.$touch();

      // バリデーション
      if (self.$v.updateParams.login.$invalid) {
        self.$$log.debug(self.$v.updateParams.login);
        return;
      }

      self.loading.login = true;

      try {
        await AuthService.updateEmail({
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          new_email: self.updateParams.login.email,
        });

        self.setMessage({
          color: 'success',
          text: MESSAGE_UPDATE_DONE,
        });

        self.loading.login = false;

        self.setUserAttributesSpecified({ email: self.updateParams.login.email });

        self.showedDialog.loginMailAddress = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading.login = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    async updateSubMail() {
      const self = this;

      self.$v.updateParams.subMail.$touch();

      // バリデーション
      if (self.$v.updateParams.subMail.$invalid) {
        self.$$log.debug(self.$v.updateParams.subMail);
        return;
      }

      self.loading.subMail = true;

      try {
        const updateParams = {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          billing_mails: spritAndTrim(self.updateParams.subMail.billing_mails, ','),
          contact_mails: spritAndTrim(self.updateParams.subMail.contact_mails, ','),
          failure_mails: spritAndTrim(self.updateParams.subMail.failure_mails, ','),
          /* eslint-enable @typescript-eslint/camelcase */
        };

        await PartnerService.updateSubMail(updateParams);

        self.subMail = updateParams;

        self.setMessage({
          color: 'success',
          text: MESSAGE_UPDATE_DONE,
        });

        self.loading.subMail = false;
        self.showedDialog.otherMailAddress = false;

        await AuthService.tokenRefresh();
      } catch (error) {
        self.loading.subMail = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },
  validations() {
    return {
      updateParams: {
        account: {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          company_name: {
            maxLength: maxLength(255),
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          family_name: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxLength: maxLength(255),
            /* eslint-enable vue/sort-keys */
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          family_name_kana: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxLength: maxLength(255),
            containsOnlyFullWidthKatakana: this.$$validators.containsOnlyFullWidthKatakana,
            /* eslint-enable vue/sort-keys */
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          given_name: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxLength: maxLength(255),
            /* eslint-enable vue/sort-keys */
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          given_name_kana: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxLength: maxLength(255),
            containsOnlyFullWidthKatakana: this.$$validators.containsOnlyFullWidthKatakana,
            /* eslint-enable vue/sort-keys */
          },
        },
        allowIPAddresses: {
          ipaddrs: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxCountDividedBy: this.$$validators.maxCountDividedBy(10, '\n', true),
            ipAddressDividedBy: this.$$validators.ipAddressDividedBy(),
            uniqueIPAddressBulk: this.$$validators.uniqueIPAddressBulk('\n', true),
            /* eslint-enable vue/sort-keys */
          },
        },
        login: {
          email: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            email,
            maxLength: maxLength(128),
            /* eslint-enable vue/sort-keys */
          },
        },
        subMail: {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          billing_mails: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxCount: this.$$validators.maxCountDividedBy(10),
            emailBulk: this.$$validators.emailBulk(),
            uniqueEmailBulk: this.$$validators.uniqueEmailBulk(),
            maxLengthEmailBulk: this.$$validators.maxLengthEmailBulk(128),
            /* eslint-enable vue/sort-keys */
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          contact_mails: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxCount: this.$$validators.maxCountDividedBy(10),
            emailBulk: this.$$validators.emailBulk(),
            uniqueEmailBulk: this.$$validators.uniqueEmailBulk(),
            maxLengthEmailBulk: this.$$validators.maxLengthEmailBulk(128),
            /* eslint-enable vue/sort-keys */
          },
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          failure_mails: {
            // エラーメッセージを表示したい順序で定義する
            /* eslint-disable vue/sort-keys */
            required,
            maxCount: this.$$validators.maxCountDividedBy(10),
            emailBulk: this.$$validators.emailBulk(),
            uniqueEmailBulk: this.$$validators.uniqueEmailBulk(),
            maxLengthEmailBulk: this.$$validators.maxLengthEmailBulk(128),
            /* eslint-enable vue/sort-keys */
          },
        },
      },
    };
  },
});
