import _ from 'lodash';
import type { PartialUserAttributes } from '@/store/modules/domain/auth';

type ResponseOfGetUserAttributes = {
  user_attributes: PartialUserAttributes;
};

// AuthService getUserAttributes()のレスポンスから任意の属性値を取得する
const getUserAttributesValue = (
  responseOfGetUserAttributes: ResponseOfGetUserAttributes,
  attributeName: string
) => {
  const attributes = _.get(responseOfGetUserAttributes, 'user_attributes', []);
  return _.get(_.filter(attributes, ['Name', attributeName])[0], 'Value');
};

export default getUserAttributesValue;
